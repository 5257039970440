.text {
    position: absolute;
    bottom: 0;
    /* Start position (hidden below the container) */
    width: 100%;
    text-align: center;
    transition: bottom 0.3s ease-in-out;
    transform: translate(0px, 100%);
    color: white;
    /* Smooth transition */
}

.hover-container:hover .text {
    bottom: 50%;
    transform: translate(0px, 50%);
    /* End position (fully visible) */
}