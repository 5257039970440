div.table {
    >div.tr:nth-child(odd) {
        background-color: rgba(218, 218, 218, 0.2);
    }

    >div.tr:nth-child(even) {
        background-color: white;
    }
}

@media (max-width: 1280px) {
    div.mobilecontent{
        height: calc(100vh - 240px);
    }
}

@media (max-width: 744px) {
    div.mobilecontent{
        height: calc(100vh - 290px);
    }
}


