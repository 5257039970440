
table.tablecontainer tr td::first-child {
    min-width: '380px';
}

table.tablecontainer tr:not(first-child) {
    height: 48px;
}

table.tablecontainer tr:first-child td:not(first-child) {
    padding-top: 16px;
    padding-bottom: 16px;
}

table.tablecontainer tr:first-child td {
    border-top-width: 0;
}

table.tablecontainer tr td:first-child {
    padding-left: 20px;
    border-left-width: 0;
}

table.tablecontainer tr td:last-child {
    border-right-width: 0;
}

table.tablecontainer td {
    border: 1px solid #DADADA;
}