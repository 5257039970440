div.open-button {
    div.arrow-container {
        width: 40px;
        transition: width 1s;
    }

    div.title {
        color: white;
        transition: color 1s;
    }

    .arrow{
        transition: transform 1s;
    }
}

div.open-button:hover {
    div.arrow-container {
        width: 100%;
    }

    div.title {
        color: black;
    }

    .arrow {
        transform: rotate(45deg);
    }
}