
@media (max-width: 744px) {
  div.tr {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  div.tr>div:nth-child(3) {
      display: none;
  }
}


@media (max-width: 560px) {
  div.tr {
      grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  div.tr>div:nth-child(2){
      display: none;
  }
}

@media (min-width: 1280px) and (max-width: 1575px) {
  .headertitle {
      width: min-content;
  }
}