div.tr {
    padding: 5px 15px 5px 15px;
}

div.tbody {
    >div.tr:nth-child(even) {
        background-color: rgba(218, 218, 218, 0.2);
    }

    >div.tr:nth-child(odd) {
        background-color: white;
    }
}

div.mobileitems {
    div.mobileitem:nth-child(even) {
        background-color: rgba(218, 218, 218, 0.1);
    }

    div.mobileitem:nth-child(odd) {
        background-color: white;
    }
}