.stepper {
    display: flex;
    padding: 0;
    list-style: none;
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        content: "";
        width: calc(100% - 20px);
        background: rgb(0, 0, 0, 0.1);
    }
}
/* flex gap-[3px] items-center text-my-blue */
.stepper_item {
    display: flex;
    gap: 3px;
    align-items: center;
    background: white;
    margin: 0 0 0 -19px;
    -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);

    &.complete {
        background: rgba(255, 255, 255, 0.8)
    }

    &:first-child {
        flex-grow: 1;
        justify-content: end;
        -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    }

    &:last-child {
        flex-grow: 1;
        -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}