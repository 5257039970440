@keyframes fadeInOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.icon {
    /* position: absolute; */
    top: 5%;
    transition: opacity 0.5s ease-in-out;
}

.icon1 {
    opacity: 1;
    height: 24;
}

.icon2 {
    opacity: 0;
    height: 24;
}

.icon1.animate {
    animation: fadeInOut 2.5s infinite;
}

.icon2.animate {
    animation: fadeInOut 2.5s infinite;
}