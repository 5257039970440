.gradient-border {
    position: relative;
    background: white;
    /* Background of the content */
    border-radius: 5px;
    /* Border radius of the content */
}

.gradient-border::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(180deg, #FFFFFF, #4ebbbb);
    border-radius: 5px;
    /* Border radius + border width */
}