div.number::after {
    content: "";
    display: inline-block;
    width: 200px; /* Width of the image */
    height: 50px; /* Height of the image */
    background-image: url('http://localhost:3000/image/Landing/Landing-Workflow-Arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 40px;
    transform: translate(100%, 0%);
}

@media (max-width: 1280px) {
    div.number::after {
        display: none;
    }
}