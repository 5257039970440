.container {
    height: calc(100vh - 75px);
}

.leftcontent {
    height: calc(100vh - 225px);
    min-height: calc(100vh - 225px);
}

.rightcontent {
    height: calc(100vh - 480px);
}

@media (max-width: 1280px) {

    .leftcontent {
        min-height: calc(100vh - 290px);
    }

    .container,
    .leftcontent,
    .rightcontent {
        height: auto;
    }
}