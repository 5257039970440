@media (max-width: 1280px) {
    div.tr {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    div.tr>div:nth-child(2),
    div.tr>div:nth-child(3),
    div.tr>div:nth-child(7) {
        display: none;
    }
}

@media (max-width: 744px) {
    div.tr {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    div.tr>div:nth-child(6) {
        display: none;
    }
}


@media (max-width: 560px) {
    div.tr {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    div.tr>div:nth-child(4),
    div.tr>div:nth-child(5) {
        display: none;
    }
}