.slick-prev:before, .slick-next:before{
    font-size: 60px!important;
}

.slick-prev, .slick-next {
    width: 60px!important;
    height: 60px!important;
    z-index: 1000!important;
}

.slick-prev {
    transform: translate(50%, -50%)!important;
}

.slick-next {
    transform: translate(-50%, -50%)!important;
}

